import React from 'react';
import { motion } from 'framer-motion';

export const CardItem1 = (props) => {
  const textAnimation = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation2 = {
    hidden: {
      x:200,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation3 = {
    hidden: {
      x:-200,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation4 = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  return (
    <>
      <motion.li 
            className='cards__item'
            initial='hidden'
            whileInView='visible'
      >
        <motion.div 
            className='cards__item__link' 
            to={props.path}
            variants={textAnimation3}
            custom={2}
        >
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </motion.div>
      </motion.li>
      
    </>
  );
}

export const CardItem2 = (props) => {
  const textAnimation2 = {
    hidden: {
      x:200,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation3 = {
    hidden: {
      x:-200,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  return (
    <>
    <motion.li 
            className='cards__item'
            initial='hidden'
            whileInView='visible'
      >
        <motion.div 
            className='cards__item__link' 
            to={props.path}
            variants={textAnimation2}
            custom={2}
        >
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </motion.div>
      </motion.li>
    </>
  );
}
export const CardItem3 = (props) => {
  const textAnimation = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation2 = {
    hidden: {
      x:200,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation3 = {
    hidden: {
      x:-200,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation4 = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  return (
    <>
    <motion.li 
            className='cards__item'
            initial='hidden'
            whileInView='visible'
      >
        <motion.div 
            className='cards__item__link' 
            to={props.path}
            variants={textAnimation4}
            custom={3}
        >
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </motion.div>
      </motion.li>
    </>
  );
}

