import img__about1 from '../../images/price_img2/aboutus1.jpg';
import img__about2 from '../../images/price_img2/about2.jpg';
import img__about3 from '../../assets/img/imgs1/uslugi.jpg';

import img__about5 from '../../images/price_img2/about3.jpg';

export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightTxt: true,
  lightTxtSub: true,
  imgStart: false,
  img: img__about1,
  alt: "",
  topLine: "Лучший массаж",
  heading: "О нас",
  subtitle:
    "Добро пожаловать на страницу самого лучшего массажа в городе Мурманск. Для Вас на выбор более 30-ти видов массажа и СПА-услуг. У нас в студии уже более 4-х исполнительниц чувственного массажа, их профессиональный уровень каждый день повышается! Для каждого клиента предусмотрены все удобства! До и после процедуры у Вас есть возможность, принять теплый и комфортный душ, а так же насладиться вкусным чаем в приятной беседе с мастером! Одноразовые тапочки, Махровые полотенца, Прохладительные напитки, Элитный ПРЕМИАЛЬНЫЙ Бар и Наша качественная продукция. Наши возможности и программы постоянно пополняются, это значит, что КАЖДЫЙ найдет для себя что-то подходящее.",
  buttonTxt: "Записаться",
  btnPrimary: true,
  btnDarkTxt: true,
  btnDarkTxtHover: true
};

export const homeObjTwo = {
  id: "vacancies",
  lightBg: false,
  lightTxt: true,
  lightTxtSub: true,
  imgStart: true,
  img: img__about2,
  alt: "Login screen image",
  topLine: "Вакансии и карьера",
  heading: "Работа в сфере массажного искусства",
  subtitle:
    "Связь с руководством по поводу трудоустройства осуществляется по номеру: WhatsApp +79009420757 или по почте: super.go-ru2013@mail.ru",
  buttonTxt: "Подробнее",
  btnPrimary: true,
  btnDarkTxt: true,
  btnDarkTxtHover: true
};

export const homeObjThree = {
  id: "contacts",
  lightBg: true,
  lightTxt: false,
  lightTxtSub: false,
  imgStart: false,
  img: img__about3,
  alt: "Graphics image",
  topLine: "Контакты",
  heading: "Связаться с нами",
  subtitle:
    "Для отзывов и предложений от НАШИХ клиентов, мы предоставляем средство связи с НАМИ: почта super.go-ru2013@mail.ru с целью ОБРАТНОЙ СВЯЗИ ",
  // subtitle1:"Почта: super.go-ru2013@mail.ru",
  // subtitle2:"Номер: +79009420757",
    buttonTxt: "Записаться",
  btnPrimary: true,
  btnDarkTxt:true,
  btnDarkTxtHover: true
};

export const Сonditions = {
  id: "conditions",
  lightBg: false,
  lightTxt: true,
  lightTxtSub: true,
  imgStart: true,
  img: img__about5,
  alt: "Login screen image",
  topLine: "Меры конфиденциальности и анонимности",
  heading: "Правила посещения и Условия оказания услуг",
  subtitle:
    "Фото и данные исполнителей могли быть изменены, с целью безопасности и защиты мастеров/исполнителей в сети. Условная «бесплатность» БАРА регламентируется выбранной программой и может не входить в финальную стоимость услуги. Есть противопоказания, проконсультируйтесь со специалистом.",
    buttonTxt: "Подробнее",
  btnPrimary: true,
  btnDarkTxt: true,
  btnDarkTxtHover: true
};
