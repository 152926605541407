import {
  FooterContainer,
  Wrapper,
  LinksContainer,
  LinksWrapper,
  LinkItens,
  LinkTitle,
  FooterLink,
  SocialMedia,
  SMwrap,
  Logo,
  Copy,
  SocialIcons,
  IconLink
} from "./footStyles";
import React from 'react';
import '../Nav/Navbar.css';
import { GiSelfLove } from "react-icons/gi";
import { 
    NavLinks, 
  } from "../Nav/navStyles";
import logo_footer from '../../images/logo-footer.png';
import "./Footer.css";

const Footer = () => {
  return (
    <FooterContainer id="footer">
      <Wrapper>
        <LinksContainer>
          <LinksWrapper>
            <LinkItens>
              <LinkTitle>О нас</LinkTitle>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Карьера</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Поддержка</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Как это работает</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Условия</IconLink>
            </LinkItens>

            {/* <LinkItens>
              <LinkTitle>Видео</LinkTitle>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Агентство</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Амбассадоры</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Инвесторы</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Демо</IconLink>
            </LinkItens> */}
          </LinksWrapper>

          <LinksWrapper>
            <LinkItens>
              <LinkTitle>Контакт с нами</LinkTitle>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Контакт</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Поддержка</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Спонсоры</IconLink>
              <IconLink style={{ textDecoration:0}} href="https://vk.com/massage_murmansk51" target="_blank">Группа</IconLink>
            </LinkItens>

          </LinksWrapper>
        </LinksContainer>

        <SocialMedia>
          <SMwrap>
            <Logo to="home"><div className="" id='home'>
                    <NavLinks exact activeClassName='' to="home">
                        <img src={logo_footer} className="logo_footer"></img>
                     {/* <GiSelfLove className='logo_new' /><div className='menu__nav'>Relax For You</div> */}
                    </NavLinks>
                </div></Logo>
            <Copy>
              ©️ Relax for You - {new Date().getFullYear} All rights
              reserved.
            </Copy>

            
          </SMwrap>
        </SocialMedia>
      </Wrapper>
    </FooterContainer>
  );
};
export default Footer;
