import React from 'react';
import './menu.css';
import { HandySvg } from 'handy-svg';
import VkIcon  from '../../images/vk.svg';
import TelegramIcon from '../../images/telegram.svg';
import ViberIcon from '../../images/viber.svg';
import WhatsappIcon from '../../images/whatsapp.svg';
import InstagramIcon from '../../images/Instagram.svg';
import { motion } from 'framer-motion';

const Menu = () => {
    const items = [
        {
          title: 'Vk',
          img: VkIcon,
          link:"https://vk.com/massage_murmansk51"
        },
        {
          title: 'Telegram',
          img: TelegramIcon,
          link:"https://t.me/+79533025946"
        },
        {
          title: 'Viber',
          img: ViberIcon,
          link:"viber://add?number=__number__+79533025946"
        },
        {
          title: 'Whatsapp',
          img: WhatsappIcon,
          link:"https://wa.me/+79533025946"
        },
        {
          title: 'Instagram',
          img: InstagramIcon,
          link:"https://www.instagram.com/irina.mur_/"
        },
    ]
    const pVariants = {
        hidden: {
            x:-1000,
            opacity:0,
        },
        visible: {
            x:0,
            opacity:1,
        }
    }
    const listVariants = {
        visible: i => ({
            opacity:1,
            transition:{
                delay: i * 0.2,
            }
        }),
        hidden: { opacity:0 }
    }

  return (
    <>
      <div id="DIV_1">
	<div id="DIV_2">
		<div id="DIV_3">
			<div id="DIV_4">
				<div id="DIV_5"> 
                {items.map((el,i) => (
                        <motion.div
                        key={el}
                        variants={listVariants}
                        initial='hidden'
                        animate='visible'
                        custom={i}
                        >        
                                        <a className='menu__icons' href={el.link} id="A_8">
                                            <HandySvg
                                            src={el.img}
                                            />
                                        </a>
                                    
                        </motion.div>
                    ))}	
				</div>
			</div>
		</div>
	</div>
</div>
    </>
  )
}

export default Menu