import styled from "styled-components";
import { Link } from "react-scroll";

const containerColor = "#E40045	";
const firstColor = "#E40045	";
const firstAltColor = "#E40045	";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({ btnPrimary }) => (btnPrimary ? "#E40045		" : "#E40045	")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ btnDarkTxt }) => (btnDarkTxt ? "#010606" : "#F26D95")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ btnPrimary }) => (btnPrimary ? "#009955" : "#F26D95")};
    background: ${({ btnDarkTxtHover }) =>
      btnDarkTxtHover ? "#F23C73" : "#F26D95"};
    color: ${({ btnDarkTxtHover }) => (btnDarkTxtHover ? "#010606" : "#F26D95")};
  }
`;

export const MainButton = styled.div`
  margin-top:35px;
  border-radius: 50px;
  background: ${({ btnPrimary }) => (btnPrimary ? "#E40045	" : "#F23C73")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ btnDarkTxt }) => (btnDarkTxt ? "#010606" : "#F8DECE")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ btnPrimary }) => (btnPrimary ? "#009955" : "#F1CFBA")};
    background: ${({ btnDarkTxtHover }) =>
      btnDarkTxtHover ? "	#F23C73" : "#00bb77"};
    color: ${({ btnDarkTxtHover }) => (btnDarkTxtHover ? "#010606" : "#F1CFBA")};
  }
`;
