import React from 'react';
import './Cards.css';
import {
  CardItem1,
  CardItem2,
  CardItem3,
} from './CardItem';
import Img1 from '../../assets/img/h1.jpg'
import Img2 from '../../assets/img/h3.jpg'
import Img3 from '../../assets/img/h5.jpg'
import Img4 from '../../assets/img/j4.jpg'
import Img5 from '../../assets/img/h7.jpg'

import { motion } from 'framer-motion';

function Cards(props) {
  const textAnimation = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  return (
    <div className='cards' style={{cursor:'pointer'}}>
      <motion.div
        initial='hidden'
        whileInView='visible'
      >
        <motion.h1 
          className='hero__cards'
          variants={textAnimation}
          custom={1}
        >
          Почему клиенты выбирают именно НАС
        </motion.h1>
      </motion.div>
      <div className='cards__container'>
     
        <div className='cards__wrapper'>
          
          <ul className='cards__items'>
              <CardItem1
                src={Img1}
                text='Комфортная и уютная атмосфера'
                label='Уникальность'
              />
            <CardItem2
              src={Img2}
              text='Бесплатный бар и Wi-Fi'
              label='Эстетика'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem3
              src={Img3}
              text='Квалифицированные мастера и исполнительницы'
              label='Профессионализм'
            />
            <CardItem3
              src={Img4}
              text='Одноразовые и высококачественные материалы'
              label='Индивидуальность'
            />
            <CardItem3
              src={Img5}
              text='Полная конфиденциальность для наших гостей'
              label='Приватность'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
