import React,{ useState } from 'react';
import './desc.css';

const desc = (props) => {
  return (
    <>

      <h1 style={{color:'white'}}>{props.desc_name}</h1>
      <h1 style={{color:'black'}}>{props.desc_name_black}</h1>

      <div className='desc_item'>{props.desc_age}</div>
      <div className='desc_item'>{props.desc_experience}</div>
      <div className='desc_item'>{props.desc_height}</div>
      <div className='desc_item'>{props.desc_weight}</div>
    
    </>
  )
}

export default desc;