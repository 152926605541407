import { generateId } from '../utils';
import Desc from './desc';

import img1 from '../../img/Photos1/img1.jpg';
import img2 from '../../img/Photos1/img2.jpg';
import img3 from '../../img/Photos1/img3.jpg';
import img4 from '../../img/Photos1/img1.jpg';

import img5 from '../../img/Photos1/img8.jpg';
import img6 from '../../img/Photos1/img9.jpg';
import img7 from '../../img/Photos1/img10.jpg';
import img8 from '../../img/Photos1/img11.jpg';

import img9 from '../../img/Photos1/img4.jpg';
import img10 from '../../img/Photos1/img5.jpg';
import img11 from '../../img/Photos1/img12.jpg';
import img12 from '../../img/Photos1/img13.jpg';

import img1_m from '../../img/Masters/Alexa1.jpg';
import img2_m from '../../img/Masters/Violetta1.jpg';
import img3_m from '../../img/Masters/Lala1.jpg';
import img4_m from '../../img/Masters/Laura1.jpg';

import img5_m from '../../img/Masters/stella2.jpg';
import img6_m from '../../img/Masters/leyla1.jpg';
import img7_m from '../../img/Masters/antonella1.jpg';
import img8_m from '../../img/Masters/oxaneta1.jpg';

import alexa1 from '../../img/Masters/Alexa1.jpg';
import alexa2 from '../../img/Masters/Alexa2.jpg';
import alexa3 from '../../img/Masters/Alexa3.jpg';

import violetta1 from '../../img/Masters/Violetta1.jpg';
import violetta2 from '../../img/Masters/Violetta2.jpg';
import violetta3 from '../../img/Masters/Violetta3.jpg';

import lala1 from '../../img/Masters/Lala1.jpg';
import lala2 from '../../img/Masters/Lala2.jpg';
import lala3 from '../../img/Masters/Lala3.jpg';

import laura1 from '../../img/Masters/Laura1.jpg';
import laura2 from '../../img/Masters/Laura2.jpg';
import laura3 from '../../img/Masters/Laura3.jpg';

import stella1 from '../../img/Masters/stella1.jpg';
import stella2 from '../../img/Masters/stella2.jpg';
import stella3 from '../../img/Masters/stella3.jpg';

import leyla1 from '../../img/Masters/leyla1.jpg';
import leyla2 from '../../img/Masters/leyla2.jpg';
import leyla3 from '../../img/Masters/leyla3.jpg';

import antonella1 from '../../img/Masters/antonella1.jpg';
import antonella2 from '../../img/Masters/antonella2.jpg';
import antonella3 from '../../img/Masters/antonella3.jpg';

import oxaneta1 from '../../img/Masters/oxaneta1.jpg';
import oxaneta2 from '../../img/Masters/oxaneta2.jpg';
import oxaneta3 from '../../img/Masters/oxaneta3.jpg';

import img1_o from '../../img/b1.jpg';
import img2_o from '../../img/b2.jpg';
import img3_o from '../../img/b3.jpg';

import img4_o from '../../img/b4.jpg';
import img5_o from '../../img/b5.jpg';
import img6_o from '../../img/b6.jpg';

import img7_o from '../../img/b7.jpg';
import img8_o from '../../img/b8.jpg';
import img9_o from '../../img/b9.jpg';

import img10_o from '../../img/b10.jpg';
import img11_o from '../../img/b11.jpg';


import new_poster1 from '../../img/Videos/poster_new1.png';
import poster3 from '../../../../images/price_imgs3/poster3.png';
import poster4 from '../../../../images/price_imgs3/poster4.png';
import poster5 from '../../../../images/price_imgs3/poster5.png';
import poster6 from '../../../../images/price_imgs3/poster6.png';

import poster7 from '../../../../images/price_imgs3/poster7.png';
import poster8 from '../../../../images/price_imgs3/poster8.png';
import poster9 from '../../../../images/price_imgs3/poster9.png';
import poster10 from '../../../../images/price_imgs3/poster10.png';
import poster11 from '../../../../images/price_imgs3/poster11.png';
import poster12 from '../../../../images/price_imgs3/poster12.png';
import poster13 from '../../../../images/price_imgs3/poster13.png';


import video1 from '../../img/Videos/new1.mp4';
import video2 from '../../img/Videos/new2.mp4';
import video3 from '../../../../images/price_imgs3/3.mp4';
import video4 from '../../../../images/price_imgs3/4.mp4';
import video5 from '../../../../images/price_imgs3/5.mp4';
import video6 from '../../../../images/price_imgs3/6.mp4';

import video7 from '../../../../images/price_imgs3/7.mp4';
import video8 from '../../../../images/price_imgs3/8.mp4';
import video9 from '../../../../images/price_imgs3/9.mp4';
import video10 from '../../../../images/price_imgs3/10.mp4';
import video11 from '../../../../images/price_imgs3/11.mp4';
import video12 from '../../../../images/price_imgs3/12.mp4';
import video13 from '../../../../images/price_imgs3/11.mp4';

import Button from './Button';
import React from 'react'


export const CATEGORIES = {
	editorial: 'editorial',
	music: 'music',
	creative: 'creative',
	video: 'video',
	blog: 'blog',
};

export const CATEGORIES_1 = {
	Галерея: 'Галерея',
	Мастера: 'Мастера',
	Видео: 'Видео',
	Отзывы: 'Отзывы',
};


export const photos = [
	{
		id: generateId(),
		src: img1,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			"Салон Relax For You",
		urls: [
			<img src={img3} />,
			<img src={img2} />,
			<img src={img1} />
		],
	},
	{
		id: generateId(),
		src: img2,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			['Салон Relax For You'],
			urls: [
				<img src={img3} />,
				<img src={img2} />,
				<img src={img1} />
			],
	},
	
	{
		id: generateId(),
		src: img3,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Салон Relax For You',
			urls: [
				<img src={img3} />,
				<img src={img2} />,
				<img src={img1} />
			],
	},
	{
		id: generateId(),
		src: img4,
		title: "Галерея",
		category: [CATEGORIES_1.Галерея],
		description:
			'Салон Relax For You',
			urls: [
				<img src={img3} />,
				<img src={img2} />,
				<img src={img1} />
			],
	},
	{
		id: generateId(),
		src: img5,
		title: "Галерея",
		category: [CATEGORIES_1.Галерея],
		description:
			'Салон Relax For You',
			urls: [
				<img src={img7} />,
				<img src={img5} />,
				<img src={img6} />
			],
	},
	{
		id: generateId(),
		src: img6,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Салон Relax For You',
			urls: [
				<img src={img7} />,
				<img src={img5} />,
				<img src={img6} />
			],
	},
	{
		id: generateId(),
		src: img7,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Салон Relax For You',
			urls: [
				<img src={img7} />,
				<img src={img5} />,
				<img src={img6} />
			],
	},
	{
		id: generateId(),
		src: img8,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Салон Relax For You',
			urls: [
				<img src={img7} />,
				<img src={img5} />,
				<img src={img6} />
			],
	},
	{
		id: generateId(),
		src: img9,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Студия Эстетик SPA',
			urls: [
				<img src={img10} />,
				<img src={img9} />,
				<img src={img11} />
			],
	},
	{
		id: generateId(),
		src: img10,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Студия Эстетик SPA',
			urls: [
				<img src={img10} />,
				<img src={img9} />,
				<img src={img11} />
			],
	},
	{
		id: generateId(),
		src: img11,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Студия Эстетик SPA',
			urls: [
				<img src={img10} />,
				<img src={img9} />,
				<img src={img11} />
			],
	},
	{
		id: generateId(),
		src: img12,
		title: 'Галерея',
		category: [CATEGORIES_1.Галерея],
		description:
			'Студия Эстетик SPA',
			urls: [
				<img src={img10} />,
				<img src={img9} />,
				<img src={img11} />
			],
	},
	{
		id: generateId(),
		src: img1_m,
		title: 'Алекса',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Алекса'
      		desc_age='Возраст: 21 год'
      		desc_experience='Опыт: 3 года'
      		desc_height='Рост: 174 см'
      		desc_weight='Вес: 51 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Алекса'
			desc_age='Возраст: 21 год'
			desc_experience='Опыт: 3 года'
			desc_height='Рост: 174 см'
			desc_weight='Вес: 51 кг'
		  />,
		  urls: [
			<img src={alexa1} />,
			<img src={alexa2} />,
			<img src={alexa3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img2_m,
		title: 'Жасмин',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Жасмин'
      		desc_age='Возраст: 25 лет'
      		desc_experience='Опыт: 5 лет'
      		desc_height='Рост: 161 см'
      		desc_weight='Вес: 50 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Жасмин'
			desc_age='Возраст: 25 лет'
			desc_experience='Опыт: 5 лет'
			desc_height='Рост: 161 см'
			desc_weight='Вес: 50 кг'
		  />,
		  urls: [
			<img src={violetta1} />,
			<img src={violetta2} />,
			<img src={violetta3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img3_m,
		title: 'Ляля',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Ляля'
      		desc_age='Возраст: 31 год'
      		desc_experience='Опыт: 5 лет'
      		desc_height='Рост: 154 см'
      		desc_weight='Вес: 60 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Ляля'
			desc_age='Возраст: 31 год'
			desc_experience='Опыт: 5 лет'
			desc_height='Рост: 154 см'
			desc_weight='Вес: 60 кг'
		  />,
		  urls: [
			<img src={lala1} />,
			<img src={lala2} />,
			<img src={lala3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img4_m,
		title: 'Ирис',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Ирис'
      		desc_age='Возраст: 25 лет'
      		desc_experience='Опыт: 3 года'
      		desc_height='Рост: 167 см'
      		desc_weight='Вес: 65 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Ирис'
			desc_age='Возраст: 25 лет'
			desc_experience='Опыт: 3 года'
			desc_height='Рост: 167 см'
			desc_weight='Вес: 65 кг'
		  />,
		  urls: [
			<img src={laura1} />,
			<img src={laura2} />,
			<img src={laura3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img5_m,
		title: 'Крис',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Крис'
      		desc_age='Возраст: 27 лет'
      		desc_experience='Опыт: 4 года'
      		desc_height='Рост: 169 см'
      		desc_weight='Вес: 63 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Крис'
			desc_age='Возраст: 27 лет'
			desc_experience='Опыт: 4 года'
			desc_height='Рост: 169 см'
			desc_weight='Вес: 63 кг'
		  />,
		  urls: [
			<img src={stella1} />,
			<img src={stella2} />,
			<img src={stella3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img6_m,
		title: 'Эля',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Эля'
      		desc_age='Возраст: 23 года'
      		desc_experience='Опыт: 5 лет'
      		desc_height='Рост: 161 см'
      		desc_weight='Вес: 53 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Эля'
			desc_age='Возраст: 23 года'
			desc_experience='Опыт: 5 лет'
			desc_height='Рост: 161 см'
			desc_weight='Вес: 53 кг'
		  />,
		  urls: [
			<img src={leyla1} />,
			<img src={leyla2} />,
			<img src={leyla3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img7_m,
		title: 'Алиса',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Алиса'
      		desc_age='Возраст: 20 лет'
      		desc_experience='Опыт: 3 года'
      		desc_height='Рост: 160 см'
      		desc_weight='Вес: 53 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Алиса'
			desc_age='Возраст: 20 лет'
			desc_experience='Опыт: 3 года'
			desc_height='Рост: 160 см'
			desc_weight='Вес: 53 кг'
		  />,
		  urls: [
			<img src={antonella1} />,
			<img src={antonella2} />,
			<img src={antonella3} />
		  ],
		  button: <Button />
	},
	{
		id: generateId(),
		src: img8_m,
		title: 'Вики',
		category: [CATEGORIES_1.Мастера],
		description:
			<Desc 
			desc_name='Вики'
      		desc_age='Возраст: 21 год'
      		desc_experience='Опыт: 3 года'
      		desc_height='Рост: 173 см'
      		desc_weight='Вес: 64 кг'
			/>,
		description1:
			<Desc 
			desc_name_black='Вики'
			desc_age='Возраст: 21 год'
			desc_experience='Опыт: 3 года'
			desc_height='Рост: 173 см'
			desc_weight='Вес: 64 кг'
		  />,
		  urls: [
			<img src={oxaneta1} />,
			<img src={oxaneta2} />,
			<img src={oxaneta3} />
		  ],
		  button: <Button />
	},
	
	{
		id: generateId(),
		src: img1_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img8_o} />,
				<img src={img6_o} />,
				<img src={img2_o} />
			],
	},
	{
		id: generateId(),
		src: img2_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img8_o} />,
				<img src={img6_o} />,
				<img src={img2_o} />
			],
	},
	{
		id: generateId(),
		src: img4_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img8_o} />,
				<img src={img6_o} />,
				<img src={img2_o} />
			],
	},
	{
		id: generateId(),
		src: img5_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img10_o} />,
				<img src={img11_o} />,
				<img src={img9_o} />
			],
	},
	{
		id: generateId(),
		src: img6_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img8_o} />,
				<img src={img6_o} />,
				<img src={img2_o} />
			],
	},
	{
		id: generateId(),
		src: img7_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img8_o} />,
				<img src={img6_o} />,
				<img src={img2_o} />
			],
	},
	{
		id: generateId(),
		src: img8_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img1_o} />,
				<img src={img2_o} />,
				<img src={img3_o} />
			],
	},
	{
		id: generateId(),
		src: img9_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img1_o} />,
				<img src={img2_o} />,
				<img src={img3_o} />
			],
	},
	{
		id: generateId(),
		src: img10_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img1_o} />,
				<img src={img2_o} />,
				<img src={img3_o} />
			],
	},
	{
		id: generateId(),
		src: img11_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img1_o} />,
				<img src={img2_o} />,
				<img src={img1_o} />
			],
	},
	{
		id: generateId(),
		src: img3_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img4_o} />,
				<img src={img3_o} />,
				<img src={img5_o} />
			],
	},
	{
		id: generateId(),
		src: img2_o,
		title: 'Отзывы',
		category: [CATEGORIES_1.Отзывы],
		description:
			"Отзыв",
			urls: [
				<img src={img1_o} />,
				<img src={img2_o} />,
				<img src={img1_o} />
			],
	},
	{
		id: generateId(),
		src: new_poster1,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video1,
	},
	{
		id: generateId(),
		src: new_poster1,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video2,
	},
	{
		id: generateId(),
		src: poster3,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video3,
	},
	{
		id: generateId(),
		src: poster4,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video4,
	},
	{
		id: generateId(),
		src: poster5,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video5,
	},
	{
		id: generateId(),
		src: poster6,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video6,
	},
	{
		id: generateId(),
		src: poster7,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video7,
	},
	{
		id: generateId(),
		src: poster8,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video8,
	},
	{
		id: generateId(),
		src: poster9,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video9,
	},
	{
		id: generateId(),
		src: poster10,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video10,
	},
	{
		id: generateId(),
		src: poster11,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video11,
	},
	{
		id: generateId(),
		src: poster12,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video12,
	},
	{
		id: generateId(),
		src: poster13,
		title: 'Видео',
		category: [CATEGORIES_1.Видео],
		description:
			"",
		video:video13,
	},
];
