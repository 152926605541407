import React from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  BtnWrapper,
  ArrowForward,
  ArrowRight
} from "./heroStyles";
import Image from '../../assets/img/massage__background.jpg';
import { motion } from 'framer-motion';
import Button from "../Button";
import Menu from './menu.jsx';
import Logo1 from '../../images/price_img2/Page 1 (1).svg';
import Video1 from '../../assets/video/new_video_bg1.mp4'


import './HeroSection.css';

const HeroSection = () => {

  const textAnimation = {
    hidden: {
      x:-100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation2 = {
    hidden: {
      x:100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation3 = {
    hidden: {
      y:100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation4 = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 10,
        delay:0.5,
      }
    }
  }
  
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
 
  const [hover, setHover] = React.useState(false);
  
  const onHover = () => {
    setHover(!hover);
  };                    

  return (
   <>
    <HeroContainer id="home">
    {window.innerWidth > 700 ? 
    <>
      <HeroBg className="video__media">        
        <VideoBg className="video__background" autoPlay playsinline loop muted src={Video1} type="video/mp4" />
        {/* <img src={img1} className="image__background"/> */}
      </HeroBg>
    </> 
  : <img src={Image} className="image__background"/>}
      
      
      <HeroContent>

        
            <motion.div
            initial='hidden'
            whileInView='visible'
            >
              <HeroH1>
                  <motion.h1
                  variants={textAnimation3}
                  custom={5}
                  >
                    <div className="relax_h1">
                    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="330"
      height="231"
      version="1.1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="330"
        height="400"
        fillRule="evenodd"
        clipRule="evenodd"
        imageRendering="optimizeQuality"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox="0 0 19471.29 3386.7"
      >
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M17933.37 909.4v982.38c0 341.29 277.13 618.41 618.41 618.41h215.31c216.9 0 450.75-153.94 618.4-283.77"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M19385.49 909.4L19385.49 2509.02"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M17418.74 1708.56c0-442.02-358.32-800.33-800.34-800.33-442.02 0-800.33 358.32-800.33 800.33 0 442.02 358.32 800.33 800.33 800.33 442.02 0 800.34-358.32 800.34-800.33zM15522.62 909.4c-130.1 298.86-686.23 1749.69-987.43 2391.49"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M14055.1 909.4L14885.64 2487.51"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M12700.63 908.89c-196.09 0-321.79-1.16-493.44 65.05-83.15 32.07-165.34 85.78-271.52 161.91-6.57 4.71-47.72 32.32-54.48 37.2"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M11830.79 2510.07L11830.79 910.58"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M11316.15 1708.56c0-442.02-358.32-800.33-800.34-800.33-442.01 0-800.33 358.32-800.33 800.33 0 442.02 358.32 800.33 800.33 800.33 442.02 0 800.34-358.32 800.34-800.33z"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M8678.11 967.69L9520.64 967.69"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M8998.61 1037.19c-1.28 73.83-.57 85.4-1.18 164.73v1302.39m599.85-2401.93c-493.67 24.82-579.12 325.42-595.91 751.13-.38 9.53-.72 84.73-1.03 94.39"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M6640.06 1742.46L6568.65 1835.89 6077.47 2467.19"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M6063.66 969.38L7239.65 2469.37"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M5673.87 2073.95c-255.38 339.64-550.6 434.95-800.34 434.95-442.36 0-800.33-357.98-800.33-800.33 0-442.36 357.98-800.34 800.33-800.34 442.36 0 800.34 357.98 800.34 800.34v800.33m-1751.92-7.87l-134.8 2.35c-203.91 3.53-315.43-244.21-314.37-448.11l2.82-527.57V85.81M2960.73 2325.8c-176.05 145.14-415.09 217.65-669.54 167.7-308.03-59.46-557.76-305.67-621.93-612.53-108.24-517.34 284.28-971.69 782.58-971.69 587.62 0 922.45 800.34 300.86 800.34H1652.57"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M1371.6 2575.48L962.56 1628.99 897.3 1474.71"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M85.8 2515.95V344.59c-.11-124.57 99.08-225.53 221.54-225.53h599.37c309.44 0 560.35 255.26 560.35 570.22v198.97c0 314.96-250.91 570.23-560.35 570.23l-742.75.01"
          className="colorStrokef0c svgStroke"
        ></path>
        <path
          fill="none"
          stroke="#e40045"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="171.59"
          d="M7235.96 980.38L6744.5 1612.05 6661.26 1719.64"
        ></path>
      </svg>
    </svg>
                    </div>
                  </motion.h1>
                  <motion.div
            initial='hidden'
            whileInView='visible'
            >
                  <motion.h1
                  variants={textAnimation4}
                  custom={5}
                  >
                    <img src={Logo1} className="relax__logo"/>
                    </motion.h1></motion.div>
                </HeroH1>

                <motion.div
            initial='hidden'
            whileInView='visible'
            >
                  <motion.h1
                  variants={textAnimation3}
                  custom={4}
                  >
                <HeroH1>
                  <h1>
                  Сеть Салонов МАССАЖА и СПА-УСЛУГ<br/>
                  </h1>
                  
                  </HeroH1>
                  
                  </motion.h1></motion.div>
            </motion.div>
          <BtnWrapper>
          <motion.div
                    variants={textAnimation4}
                    custom={3}
                    initial='hidden'
                    whileInView='visible'
                    >
            <h2 style={{color:'white'}}>
   
            </h2></motion.div>
          <motion.div
                    variants={textAnimation4}
                    custom={3}
                    initial='hidden'
                    whileInView='visible'
                    ><Menu />
                    </motion.div>
          </BtnWrapper>
        <HeroP>
        <motion.div
                  variants={textAnimation2}
                  custom={2}
                  initial='hidden'
                  whileInView='visible'
                  >
                    
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            custom={2}
            >
            <br /><div className="text1">Откройте Для Себя Мир Эстетики и Удовольствия!</div>
            </motion.div>
        </motion.div>
          <ul>
          
        
          </ul>
          
        </HeroP>
          <motion.div
          
          initial="hidden"
          custom={3}
          
          whileHover={{
            
            scale:1.25
          }}
          transition={{
            delay:0.10
          }}
          >
              <motion.div
                    variants={textAnimation3}
                    custom={2}
                    initial='hidden'
                    whileInView='visible'
                    >
              <a href="tel:+79533025946" style={{ textDecoration:0, color:'black'}}>
                <Button  />
              </a> 
            </motion.div>
          </motion.div>
        </HeroContent>
       
      </HeroContainer>
      </>
  );
};

export default HeroSection;
