import React,{useState} from 'react';
import {FiXCircle} from "react-icons/fi";
import './Navbar.css';
import { GiSelfLove } from "react-icons/gi";
import {
    Nav,
    NavLinks,
  } from "./navStyles";
  import { FaBars } from "react-icons/fa";



const Navbarmenu = () => {

    const [scrollNav, setScrollNav] = React.useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      //80px = 5rem -> Header height
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  //Activate the function above:
  React.useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
  };

    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
      
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }

   

    return (
        <Nav scrollNav={scrollNav}>
    <header className="header__middle">
        <div className="container__navbar">
            <div className="row">

            
                <div className="header__middle__logo" id='home'>
                    <NavLinks exact activeClassName='is-actisve' to="home">
                     <GiSelfLove className='logo_new' /><div className='menu__nav'>by Эстетик SPA</div>
                    </NavLinks>
                </div>

                <div className="header__middle__menus">
                    <nav className="main-nav " >

                    {/* Responsive Menu Button */}
                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FaBars />   </span>
                    </>}


                    <ul className={boxClass.join(' ')}>
                    <li  className="menu-item" >
                        <NavLinks exact activeClassName='is-active' onClick={toggleClass} to='about'> О нас </NavLinks> 
                    </li>
                    
                    <li className="menu-item" ><NavLinks onClick={toggleClass} activeClassName='is-active' to='price'> Прайс </NavLinks> </li>
                    <li className="menu-item" ><NavLinks onClick={toggleClass} activeClassName='is-active' to='gallery'> Галерея </NavLinks> </li>
                    {/* <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="#"> Галерея </Link>
                        <ul className={boxClassSubMenu.join(' ')} > 
                        <li className='nav__li'><NavLinks onClick={toggleClass} activeClassName='is-active' to='gallery'> <div className = "nav__submenu">Фото </div></NavLinks> </li>
                            <li className='nav__li'><NavLinks onClick={toggleClass} activeClassName='is-active' to='videogallery'> <div className = "nav__submenu">Видео </div></NavLinks> </li>
                            <li className='nav__li'> <NavLinks  onClick={toggleClass} activeClassName='is-active'  to='gallery'><div className = "nav__submenu"> Салоны </div></NavLinks> </li>
                        </ul>
                    </li> */}
                    {/* <li className="menu-item " ><NavLinks onClick={toggleClass} activeClassName='is-active' to='reviews'> Отзывы </NavLinks> </li> */}
                    <li className="menu-item " ><NavLinks onClick={toggleClass} activeClassName='is-active' to='sliderpage'> Вакансии </NavLinks> </li>
                    {/* <li className="menu-item " ><NavLinks onClick={toggleClass} activeClassName='is-active' to='sliderpage'> Контакты </NavLinks> </li> */}
                    </ul>
                    

                  </nav>     
                </div>   



        
        
            </div>
	    </div>
    </header>
    </Nav>
    )
}

export default Navbarmenu