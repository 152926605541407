import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import HeroSection from "../../components/HeroSection/HeroSection";
import InfoSection from "../../components/InfoSection/InfoSection";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree, Сonditions
} from "../../components/InfoSection/Data";
import Footer from "../../components/Footer/Footer";
import Cards from "../../components/Cards/Cards";
import Price from '../../components/Price/Price';

import img1 from "../../assets/img/m3.jpg";
import img2 from "../../assets/img/m5.jpg";
import img3 from "../../assets/img/m4.jpg";
import img4 from "../../assets/img/m7.jpg";
import img5 from "../../assets/img/m2.jpg";
import img6 from "../../assets/img/m1.jpg";
import Navbar from '../../components/Nav/Navbar';
import { Gallery_Two } from '../../components/ModernGallery/pages/index';

import Slider from '../../components/Slider/Slider.js';

function Home() {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const galleryImages = [
    {
      img: img1
    },
    {
      img: img2
    },
    {
      img: img3
    },
    {
      img: img4
    },
    {
      img: img5
    },
    {
      img: img6
    }
  ]

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar />
      
      <HeroSection />
      

      <InfoSection {...homeObjOne} />
      {/* <Slider /> */}
      <Price />
      
      <Gallery_Two id='gallery' />
      <Cards />
      <Slider />
      {/* <VideoPlayer /> */}
      {/* <InfoSection {...homeObjTwo} /> */}
      {/* <Salons /> */}
     
      {/* <Reviews /> */}
      {/* <InfoSection {...homeObjThree} /> */}
      {/* <InfoSection {...Сonditions} /> */}
      <Footer />
      
    </>
  );
}

export default Home;
