import React from 'react';
import './Button.css';

const Button = () => {
  return (
    <>
        <a href='https://wa.me/+79533025946'><button className='price__btn'>Записаться на массаж</button></a>
    </>
  )
}

export default Button