import React,{ useState } from 'react';
import './Price.css';
import { motion, AnimatePresence } from 'framer-motion';

import img1 from '../../assets/img/imgs1/korolevsky.jpg';
import img2 from '../../assets/img/imgs1/emperor.jpg';
import img3 from '../../assets/img/imgs1/vostochniy.jpg';
import img4 from '../../assets/img/imgs1/tantricheskiy.jpg';

import img5 from '../../assets/img/imgs1/tandem.jpg';
import img6 from '../../assets/img/imgs1/vip.jpg';

import main_img1 from '../../assets/img/imgs1/main1.jpg';
import main_img2 from '../../assets/img/imgs1/main2.jpg';

import img7 from '../../assets/img/imgs1/litso.jpg';
import img8 from '../../assets/img/imgs1/scrab.jpg';
import img9 from '../../assets/img/imgs1/lingamerotika.jpg';

import img10 from '../../assets/img/imgs1/spina.jpg';
import img11 from '../../assets/img/imgs1/bodyerotic.jpg';
import img12 from '../../assets/img/imgs1/prostata.jpg';

import '../../images/bg_purple.png';

const Price = (props) => {

  const { 
    title = 'click me',
    children,
  } = props;
  
  const [ isVisible1,setVisible1 ] = useState(false);
  const [ isVisible2,setVisible2 ] = useState(false);
  const [ isVisible3,setVisible3 ] = useState(false);

  const handleVisibility1 = () => setVisible1(!isVisible1);
  const handleVisibility2 = () => setVisible2(!isVisible2);
  const handleVisibility3 = () => setVisible3(!isVisible3);

  const [ showImage1, setShowImage1 ] = useState(true);
  const [ showImage2, setShowImage2 ] = useState(true);
  const [ showImage3, setShowImage3 ] = useState(true);

  const [cards_one] = useState([
    {
      title: 'Королевский',
      text: '5000 рублей',
      img: img1,
      description: 'Лучший массаж для того, что бы познакомиться с нашей студией. Боди и Лингам массаж с проработкой глубоких групп мышц и всех точек на теле. Массаж от пальцев ног до ушей и головы.'
    },
    {
      title: 'Император',
      text: '6000 рублей',
      img: img2,
      description: 'Наш хит-массаж, для оригинальных мужчин. Полный визуальный доступ, с возможностью прикосновений к мастеру. Фут-фетиш с добавлением техник из классического массажа'
    },
    {
      title: 'Восточный',
      text: '7000 рублей',
      img: img3,
      description: 'Для любителей Азиатских техник и СПА-процедур. Массаж всего тела, лица и головы. С элементами йоги и горячими камнями. Скраб тела и ванночка для ног с лепестками роз'
    },
  ])

  const [cards_two] = useState ([
    {
      title: 'Тантрический',
      text: '8000 рублей',
      img: img4,
      description: 'Для искушенных любителей эстетики и удовольствий. Взаимный массаж мастеру, Стрип-Танец, Ролевые игры, БДСМ, Фетиши'
    },
    {
      title: 'Тандем',
      text: '10000 рублей',
      img: img5,
      description: 'Выполняется двумя мастерами-исполнительницами одновременно. Взаимный танец между исполнительницами. Возможность вносить свои пожелания в ход программы. Бесплатный бар'
    },
    {
      title: 'V.I.P.',
      text: '12000 рублей',
      img: img6,
      description:'Длительность программы 2 часа. Любой массаж на выбор. Фруктовая тарелка. Бар до и после, а так же во время массажа. Возможность пригласить другого мастера'
    },])

    const [cards_one2] = useState([
      {
        title: 'Лицо',
        text: '1000 рублей',
        img: img7,
        description: 'Лицо'
      },
      {
        title: 'Скраб',
        text: '2000 рублей',
        img: img8,
        description: 'Скраб'
      },
      {
        title: 'Лингам Боди',
        text: '2500 рублей',
        img: img9,
        description: 'Лингам Боди'
      },
    ])
  
    const [cards_two2] = useState ([
      {
        title: 'Спина',
        text: '1500 рублей',
        img: img10,
        description: 'Спина'
      },
      {
        title: 'Боди Эротика',
        text: '3000 рублей',
        img: img11,
        description: 'Боди Эротика'
      },
      {
        title: 'Простата',
        text: '3000 рублей',
        img: img12,
        description:'Простата'
      },])

   

    const [menu_one] = useState ([
      {
        title: 'МАССАЖ и SPA',
        text: '3000 рублей',
        img: main_img1,
        description:'Сеть массажных салонов "Relax For You" в Мурманске, предоставляет возможность познакомиться с традиционными и инновационными техниками получения пользы и удовольствия.'
      },
      ])

      const [menu_two] = useState ([
        {
          title: 'Дополнительно',
          text: '3000 рублей',
          img: main_img2,
          description:'Мы будем рады завоевать ваше доверие и предоставить лучший сервис в Мурманске. Наши процедуры проводятся опытными профессионалами и мастерами. Вы останетесь довольны!'
        },
        ])
  
  

  const textAnimation = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation2 = {
    hidden: {
      x:100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation3 = {
    hidden: {
      x:-100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation4 = {
    hidden: {
      y:-100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }

  
  
  return (
    
      <section className='price__section'>
          <div className='price__container'>
            <motion.div
            initial='hidden'
            whileInView='visible'
            >
              <motion.h1 
                className='price__h1' 
                id='price'
                variants={textAnimation}
                custom={1}
              >
                Прайс-лист
              </motion.h1>

                </motion.div>
                
                
                        <div className='main__category'>
                          <div className='menu1'>
                            
                          {/* main category */}
                          <motion.div
                                          initial='hidden'
                                          whileInView='visible'
                                          >
                                            <motion.div
                                            variants={textAnimation4}
                                            custom={2}
                                            >
                                              <div onClick={() => setShowImage1(!showImage1)}><h2 className='text__h3'>Массаж и SPA</h2>
                                              {showImage1 ? <>
                                              {
                                                <div className='price__cards link__pointer' onClick={() => setShowImage1(showImage1)} >
                                                {
                                                  menu_one.map((menu_one,i) => (<>
                                                  <div className='price__card' key={i} >
                                                  <h3 className='price__h3'>
                                                       
                                                      </h3>
                                                      <p>
                                                      
                                                      </p>
                                                    <ul class="hover-effect-scale">  
                                                      
                                                          <li>
                                                              <img src={menu_one.img} />
                                                                <div>
                                                                  <p className='description__text'>{menu_one.description}</p>
                                                                </div>  
                                                          </li>
                                                        <button className='price__btn'>Подробнее</button>
                                                      
                                                    </ul>
                                                            </div> 
                                                            </> ))}
                                                        </div> 
                                              }</> : <AnimatePresence>
                                              {
                                                !isVisible1 && (
                                                  <>
                                                  
                                                    {/* main category */}
                                                    <motion.div
                                                  initial='hidden'
                                                  whileInView='visible'
                                                  >
                                                    <motion.div
                                                    variants={textAnimation4}
                                                    custom={2}
                                                    >
                                                      <div className='price__cards link__pointer' >
                                                              {
                                                                cards_one.map((cards_one,i) => (<>
                                                                <div className='price__card' key={i} >
                                                                <h3 className='price__h3'>
                                                                      { cards_one.title }
                                                                    </h3>
                                                                    <p>
                                                                      { cards_one.text }
                                                                    </p>
                                                                  <ul class="hover-effect-scale">  
                                                                    <a href="https://wa.me/+79533025946">
                                                                        <li>
                                                                            <img src={cards_one.img} />
                                                                              <div>
                                                                                <p className='description__text'>{cards_one.description}</p>
                                                                              </div>  
                                                                        </li>
                                                                      <button className='price__btn'>Заказать</button>
                                                                    </a>
                                                                  </ul>
                                                                          </div>
                                                                          </> ))}
                                                                      </div> 
                                                              </motion.div>
                                                            </motion.div>
                                                            <motion.div
                                                  initial='hidden'
                                                  whileInView='visible'
                                                  >
                                                    <motion.div
                                                    variants={textAnimation4}
                                                    custom={3}
                                                    >
                                                            <div className='price__cards link__pointer' >
                                                              {
                                                                cards_two.map((cards_two,i) => (<>
                                                                <div className='price__card' key={i} >
                                                                <h3 className='price__h3'>
                                                                      { cards_two.title }
                                                                    </h3>
                                                                    <p>
                                                                      { cards_two.text }
                                                                    </p>
                                                                  <ul class="hover-effect-scale">  
                                                                    <a href="https://wa.me/+79533025946">
                                                                        <li>
                                                                            <img src={cards_two.img} />
                                                                              <div>
                                                                                <p className='description__text'>{cards_two.description}</p>
                                                                              </div>  
                                                                        </li>
                                                                      <button className='price__btn'>Заказать</button>
                                                                    </a>
                                                                  </ul>
                                                                          </div>
                                                                          </> ))}
                                                                      </div> </motion.div></motion.div>
                                                  </>
                                                )
                                              }
                                                  
                                                          </AnimatePresence>} </div>
                                            
                                          </motion.div>
                          </motion.div>

                          {/* two main category */}
                          <motion.div
                                          initial='hidden'
                                          whileInView='visible'
                                          >
                                            <motion.div
                                            variants={textAnimation4}
                                            custom={2}
                                            >
                                              <div onClick={() => setShowImage2(!showImage2)}><h2 className='text__h3'>Дополнительно</h2>
                                              {showImage2 ? <>
                                              {
                                                <div className='price__cards link__pointer' onClick={() => setShowImage2(showImage2)} >
                                                {
                                                  menu_two.map((menu_two,i) => (<>
                                                  <div className='price__card' key={i} >
                                                  <h3 className='price__h3'>
                                                       
                                                      </h3>
                                                      <p>
                                                      
                                                      </p>
                                                    <ul class="hover-effect-scale">  
                                                      
                                                          <li>
                                                              <img src={menu_two.img} />
                                                                <div>
                                                                  <p className='description__text'>{menu_two.description}</p>
                                                                </div>  
                                                          </li>
                                                        <button className='price__btn'>Подробнее</button>
                                                      
                                                    </ul>
                                                            </div> 
                                                            </> ))}
                                                        </div> 
                                              }</> : <AnimatePresence>
                                              {
                                                !isVisible2 && (
                                                  <>
                                                  
                                                    {/* main category */}
                                                    <motion.div
                                                  initial='hidden'
                                                  whileInView='visible'
                                                  >
                                                    <motion.div
                                                    variants={textAnimation4}
                                                    custom={2}
                                                    >
                                                      <div className='price__cards link__pointer' >
                                                              {
                                                                cards_one2.map((cards_one2,i) => (<>
                                                                <div className='price__card' key={i} >
                                                                <h3 className='price__h3'>
                                                                      { cards_one2.title }
                                                                    </h3>
                                                                    <p>
                                                                      { cards_one2.text }
                                                                    </p>
                                                                  <ul class="hover-effect-scale">  
                                                                    <a href="https://wa.me/+79533025946">
                                                                        <li>
                                                                            <img src={cards_one2.img} />
                                                                              <div>
                                                                                <p className='description__text'>{cards_one2.description}</p>
                                                                              </div>  
                                                                        </li>
                                                                      <button className='price__btn'>Заказать</button>
                                                                    </a>
                                                                  </ul>
                                                                          </div>
                                                                          </> ))}
                                                                      </div> 
                                                              </motion.div>
                                                            </motion.div>
                                                            <motion.div
                                                  initial='hidden'
                                                  whileInView='visible'
                                                  >
                                                    <motion.div
                                                    variants={textAnimation4}
                                                    custom={3}
                                                    >
                                                            <div className='price__cards link__pointer' >
                                                              {
                                                                cards_two2.map((cards_two2,i) => (<>
                                                                <div className='price__card' key={i} >
                                                                <h3 className='price__h3'>
                                                                      { cards_two2.title }
                                                                    </h3>
                                                                    <p>
                                                                      { cards_two2.text }
                                                                    </p>
                                                                  <ul class="hover-effect-scale">  
                                                                    <a href="https://wa.me/+79533025946">
                                                                        <li>
                                                                            <img src={cards_two2.img} />
                                                                              <div>
                                                                                <p className='description__text'>{cards_two2.description}</p>
                                                                              </div>  
                                                                        </li>
                                                                      <button className='price__btn'>Заказать</button>
                                                                    </a>
                                                                  </ul>
                                                                          </div>
                                                                          </> ))}
                                                                      </div> </motion.div></motion.div>
                                                  </>
                                                )
                                              }
                                                  
                                                          </AnimatePresence>} </div>
                                            
                                          </motion.div>
                          </motion.div>

                                     </div>
                                        
                                  </div> 
                          </div> 
      </section> 
 ) 
}

export default Price