import React,{ useState } from 'react';
import { Swiper,SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import img1 from '../../images/price_imgs3/image1.jpg';
import img2 from '../../images/price_imgs3/image4.jpg';
import img3 from '../../images/price_imgs3/about3.jpg';
import img4 from '../../images/price_imgs3/image11.jpg';
import './Slider.css';
import { HiOutlineArrowNarrowLeft,HiOutlineArrowNarrowRight } from 'react-icons/hi';

export default function Slider() {
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [show4,setShow4] = useState(false);
  return (
  <>
    <Swiper
    className='relative group '
    spaceBetween={50}
    slidesPerView={1}
    navigation={{
      nextEl:'.button-next-slide',
      prevEl:'.button-prev-slide',
    }}
    modules={[Navigation]}
    >

<SwiperSlide className='swiper_slide'>
        <div className='swiper_container image relative' id='sliderpage'>
          <div className='swiper_image'><img className='swiper_img' src={img3} alt=''/></div>
            <div className='swiper_div space-y-5 title-content absolute top-[25%] left-[8rem] text-white  '>
              <h3 className='swiper_h3_1 text-[16px]'> Работа в сфере массажного искусства</h3>
                <h3 className='swiper_h3_2 text-[50px] font-[700] '>Вакансии и карьера</h3>
                {show4 && <>
                  <p className='swiper_p text-[14px] w-[40%]'>
                    Связь с руководством по поводу трудоустройства осуществляется по номеру: WhatsApp +79009420757 или по почте: relaxzone51@yandex.ru
                  </p></>}
                  <button onClick={() => setShow4(!show4)} className='swiper_button px-[2rem] bg-[#333] text-[13px] p-2 text-white'>Подробнее</button>
                  <a href='mailto:super.go-ru2013@mail.ru'><button className='ml-5 px-[2rem] bg-[#333] text-[13px] p-2 text-white'>Связаться</button></a>
            </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper_slide '>
      <div className='swiper_container image relative '>
          <img className='swiper_img ' src={img1} alt=''/>
            <div className='swiper_div space-y-5 title-content absolute top-[25%] left-[8rem] text-white'>
              <h3 className='swiper_h3_1 text-[16px]'>Статус предоставления сервиса</h3>
                <h3 className='swiper_h3_2 text-[50px] font-[700]'>Правила оказания услуг</h3>
                {show2 && <>
                  <p className='swiper_p1 swiper_p text-[14px] w-[40%] '>
                    Правила посещения студии: оплата производится до сеанса, запрещено посещение в алкогольном или ином опьянении, любой контакт с мастерами вне студии запрещён, при входе в салон установлена камера внутреннего видеонаблюдения, каждый салон находится под охраной, торг запрещён. Неуважительное обращение или отношение к мастерам недопустимо. <br/><strong className="text-[18px]">Интима строго нет</strong>
                  </p>
                  </>}
                  <button onClick={() => setShow2(!show2)} className='px-[2rem] bg-[#333] text-[13px] p-2 text-white'>Подробнее</button>
            </div>
        </div>
      </SwiperSlide>
      <SwiperSlide className='swiper_slide'>
      <div className='swiper_container image relative '>
          <img className='swiper_img' src={img2} alt=''/>
            <div className='swiper_div space-y-5 title-content absolute top-[25%] left-[8rem] text-white'>
              <h3 className='swiper_h3_1 text-[16px]'>Контакты</h3>
                <h3 className='swiper_h3_2 text-[50px] font-[700]'>Связаться с нами</h3>
                {show3 && <>
                <p className='swiper_p text-[14px] w-[40%]'>
                  Для отзывов и предложений от НАШИХ клиентов, мы предоставляем средство связи с НАМИ: почта relaxzone51@yandex.ru с целью ОБРАТНОЙ СВЯЗИ 
                  </p></>}
                  <button onClick={() => setShow3(!show3)} className='px-[2rem] bg-[#333] text-[13px] p-2 text-white'>Подробнее</button>
                  <a href='tel:+79533025946'><button className='ml-5 px-[2rem] bg-[#333] text-[13px] p-2 text-white'>Записаться</button></a>
            </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide className='swiper_slide'>
      <div className='swiper_container image relative'>
          <img className='swiper_img' src={img4} alt=''/>
            <div className='swiper_div space-y-5 title-content absolute top-[25%] left-[8rem] text-white'>
              <h3 className='swiper_h3_1 text-[16px]'>Меры конфиденциальности и анонимности</h3>
                <h3 className='swiper_h3_2 text-[50px] font-[700]'>Условия оказания услуг</h3>
                {show1 && <>
                  <p className='swiper_p text-[14px] w-[40%]'>
                  Фото и данные исполнителей могли быть изменены, с целью безопасности и защиты мастеров/исполнителей в сети. Условная «бесплатность» БАРА регламентируется выбранной программой и может не входить в финальную стоимость услуги. Есть противопоказания, проконсультируйтесь со специалистом. <br/><strong className="text-[18px]">Интима строго нет</strong>
                  </p></>}
                  <button onClick={() => setShow1(!show1)} className='px-[2rem] bg-[#333] text-[13px] p-2 text-white'>Подробнее</button>
                  
            </div>
        </div>
      </SwiperSlide>
    
      <div className='top-[50%] absolute z-10 button-next-slide group-hover:right-0 -right-[23rem] duration-500 text-white w-[55px] h-[55px] bg-[#000] grid place-items-center'>
          <HiOutlineArrowNarrowRight />
      </div>
      <div className='top-[50%] absolute z-10 button-prev-slide group-hover:left-0 -left-[23rem] duration-500 text-white w-[55px] h-[55px] bg-[#000] grid place-items-center'>
          <HiOutlineArrowNarrowLeft />
      </div>
    </Swiper>
  </>)
}