import React from 'react';
import './Button.css';

const Button = () => {
  return (
    <>
        <button class="slide-btns slide-d-left" style={{cursor: 'pointer'}}>Записаться на массаж</button>
    </>
  )
}


export default Button 