import React, { useRef,useState, useEffect } from "react";
import { MdMusicNote } from "react-icons/md";
import Sound from "../../assets/sounds/voice_and_music.mp3";

import "./MusicButton.css";

const MusicButton = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        // Автоматическое воспроизведение при загрузке компонента
        if (audioRef.current) {
          audioRef.current.play().catch((error) => {
            console.log('Автопроигрывание заблокировано браузером:', error);
          });
        }
      }, []);

      const toggleMusic = () => {
        if (audioRef.current) {
          if (isPlaying) {
            audioRef.current.pause();
          } else {
            audioRef.current.play();
          }
          setIsPlaying(!isPlaying);
        }
      };

    return (
        <div className="music-top-to-btm">
            <audio ref={audioRef} src={Sound} autoPlay loop />
            {" "}
            {showTopBtn && (
                <MdMusicNote  
                    className="music-icon-position music-icon-style"
                    onClick={toggleMusic}
                >{isPlaying ? 'Pause Music' : 'Play Music'}
                </MdMusicNote >
            )}{" "}
        </div>
    );
};
export default MusicButton;