import React from 'react';
import './index.css';
import Routes from './Routes';
import ScrollButton from './components/ScrollButton/ScrollButton';
import MusicButton from './components/MusicButton/MusicButton';

const App = () => {
  return (
    <>
      <Routes /> 
      <ScrollButton />
      <MusicButton />
    </>
  )
}

export default App