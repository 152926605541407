import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import NotFound from "./components/NotFound";
import Salons from "./pages/Salons/Salons";

export default function AppRoutes() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" index element={<Home />} exact />
          
          <Route path="/salons" element={<Salons />} />
          <Route path="*" element={<NotFound /> }/> 
          
        </Routes>
      </Router>
    </>
  );
}