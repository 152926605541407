import clsx from 'clsx';
import React from 'react';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Drawer from '../ui/drawer';
import Badge from '../ui/badge';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FaAngleDoubleDown } from "react-icons/fa";
import './button.css'; 
import './framing.css';

export default function PhotoCard({ image }) {

	// Get all <video> elements.
	
const videos = document.querySelectorAll('video');
videos.forEach (video => {
    video.addEventListener('click', (ev) => {
        ev.preventDefault();
        videos.forEach(v => v.pause())
        video.play();
    })
})

	// for(var video of document.getElementsByTagName('video')){
	// 	video.pause();
	//    }

	// Pause all <video> elements except for the one that started playing.
	function pauseOtherVideos({ target }) {
	for (const video of videos) {
		if (video !== target) {
		video.pause();
		}
	}
	}

	// Listen for the 'play' event on all the <video> elements.
	for (const video of videos) {
	video.addEventListener('play', pauseOtherVideos);
	}

	const [isOpen, setIsOpen] = React.useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const categoryParam = searchParams.get('category');

	React.useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'hidden';
		}
	}, [isOpen]);

	const bottomEl = useRef(null);

	const scrollToBottom = () => {
		bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
	};


	return (
		
		<div className='photo'>
			<div
				className={clsx(
					'photo-card group relative cursor-pointer rounded-lg overflow-hidden transition-all after:transition-all',
					'hover:after:content-none hover:after:absolute hover:after:w-full hover:after:h-full hover:after:top-0 hover:after:left-0 hover:after:z-[1] hover:after:bg-black hover:after:opacity-25'
				)}
				title={image.title}
				id={image.id}
				key={image.id}
				onClick={() => setIsOpen(true)}
				video={image.video}
			>
				{categoryParam != 'Видео' || searchParams == 'Видео' ? 
				<>
					<img
						src={image.src}
						alt={image.title}
						className='aspect-square w-full object-cover'
						
					/>
				</> : 
				<>
					<video id="video_player" alt={image.title}
						className='aspect-square w-full object-cover' playsinline style={{width:'100%'}} controls={['PlayPause','Seek','Time','Volume','Fullscreen']}>
                			<source src={image.video} type='video/mp4' /> 
					  </video>
				</>}
				
				
				<div className='absolute w-full bottom-0 left-0 -translate-x-8 text-white px-4 pb-4 z-[9] opacity-0 transition-all bg-gradient-to-t from-black to-transparent group-hover:opacity-100 group-hover:translate-x-0'>
					<p className='truncate'>{image.description}</p>
					
				</div>
			</div>
			{categoryParam != 'Видео' ? 
				<>
			<Drawer header={image.title} isOpen={isOpen} setIsOpen={setIsOpen}>
				<div className='space-y-4'>
				<div className='top-to-btm' onClick={scrollToBottom} style={{ cursor:'pointer', alignItems:'center', display:'flex',justifyContent:'center',textAlign:'center'}}>
					<FaAngleDoubleDown />Вниз
				</div>
				<Carousel>{image.urls}</Carousel>	
				
					{/* {image.description1} */}
					{image.description1}
					<p className='flex gap-2'>
						{image.category.map((c) => (
						
							<Link key={c} to={`/?category=${c}`}>
								<Badge>{c}</Badge>
							</Link>
						
						))}
					</p>
					<div ref={bottomEl}>{image.button}</div>
					
					  {/* <video playsinline style={{width:'100%'}} controls={['PlayPause','Seek','Time','Volume','Fullscreen']}>
                			<source src={image.video} type='video/mp4' /> 
					  </video> */}

					
				</div> 
			</Drawer></>:<></>}
		</div>
	);
}
