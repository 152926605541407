import React, { useState } from "react";
import { Button } from "../btnStyles";
import { motion } from 'framer-motion';

import {
  Subtitle1,
  InfoContainer,
  Wrapper,
  Row,
  Column1,
  TxtWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img
} from "./infoStyles";
import { MotionConfig } from "framer-motion";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightTxt,
  lightTxtSub,
  heading,
  subtitle,
  subtitle1,
  subtitle2,
  buttonTxt,
  img,
  alt,
  btnPrimary,
  btnDarkTxt,
  btnDarkTxtHover
}) => {

  const textAnimation = {
    hidden: {
      x:-100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation2 = {
    hidden: {
      x:100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation3 = {
    hidden: {
      y:100,
      opacity:0,
    },
    visible: custom => ({
      y:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }
  const textAnimation4 = {
    hidden: {
      x:100,
      opacity:0,
    },
    visible: custom => ({
      x:0,
      opacity:1,
      transition:{ delay:custom * 0.2 }
    })
  }

  const [show,setShow] = useState(false);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <Wrapper>
          <Row imgStart={imgStart}>
            <Column1>
            <motion.div
            initial='hidden'
            whileInView='visible'
            >
              <motion.div 
              variants={textAnimation}
              custom={1}
                  >
                <TxtWrapper>
                  <TopLine>{topLine}</TopLine>
                  <Heading lightTxt={lightTxt}>{heading}</Heading>
                  <Subtitle lightTxtSub={lightTxtSub}>{subtitle}</Subtitle>
                  
                  
                    <Subtitle1 lightTxtSub={lightTxtSub}>{subtitle1}</Subtitle1>
                    <Subtitle1 lightTxtSub={lightTxtSub}>{subtitle2}</Subtitle1>
                    
                  <BtnWrap>
                  
                    <Button
                      btnPrimary={btnPrimary ? 1 : 0}
                      btnDarkTxt={btnDarkTxt ? 1 : 0}
                      btnDarkTxtHover={btnDarkTxtHover ? 1 : 0}
                      onClick={() => setShow(!show)}
                      to="home"
                    >
                      {buttonTxt}
                    </Button>
                  </BtnWrap>
                </TxtWrapper>
                </motion.div>
              </motion.div>
            </Column1>

            <motion.div
            initial='hidden'
            whileInView='visible'
            >
              <motion.div 
              variants={textAnimation4}
              custom={3}
                  >
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
                </motion.div>
              </motion.div>
          </Row>
        </Wrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
