import React from "react";
import {
  Icon,
} from "./logInStyles";
import './Photos.css';

const Salons = () => {
  const galleryLenght = 6
  
  const images = []

  for(let i=1 ; i <= galleryLenght; i++)

  images.push(require(`../../images/z${i}.jpg`))

  
  return (
    <>
    <Icon to="/">Relax For Man 51</Icon>
            <section  className="gallery">
                <div className="container gallery__container">
                  {
                    images.map((image, index) => {
                      return <article key={index}>
                        <img src={image} alt={`Gallery Image ${index + 1}`} />
                      </article>
                    })
                  }
                </div>
           </section>
    
    </>
  );
};

export default Salons;
